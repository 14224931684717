/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Instruments
import { Link } from 'gatsby';
import Arrow from '../../assets/svg/arrow.svg';
import styles from './post-navigation.module.scss';

const PostNavigation = ({
  activeItem, posts,
}) => {
  const [isEnLng, setEnLng] = useState(false);
  useEffect(() => {
    setEnLng(window.location.pathname.indexOf('/ar') === -1);
  }, []);
  const getNextPost = () => {
    let nextPost = null;
    posts.filter((post, i, arr) => {
      if (post.node.slug === activeItem) {
        nextPost = arr[i + 1];
      }
    });
    return nextPost ? nextPost.node.slug : nextPost;
  };
  const getPrevPost = () => {
    let prevPost = null;
    posts.forEach((post, i, arr) => {
      if (post.node.slug === activeItem) {
        prevPost = arr[i - 1];
      }
    });
    return prevPost ? prevPost.node.slug : prevPost;
  };

  return (
    <div className={`${styles.pagination} ${styles.animatedTextContainer}`}>
      <Link
        to={isEnLng ? getPrevPost() : `ar/${getPrevPost()}`}
        className={`${styles.arrow} ${styles.arrowPrev} ${getPrevPost() ? '' : styles.disabled}`}
      >
        <Arrow />
      </Link>
      {posts.map((item) => (
        <>
          <Link
            to={isEnLng ? item.node.slug : `ar/${item.node.slug}`}
            className={`${styles.pagination_item} ${item.node.slug === activeItem ? styles.pagination_item_active : ''}`}
          />
        </>
      ))}
      <Link
        to={isEnLng ? getNextPost() : `ar/${getNextPost()}`}
        className={`${styles.arrow} ${styles.arrowNext} ${getNextPost() ? '' : styles.disabled}`}
      >
        <Arrow />
      </Link>
    </div>
  );
};

PostNavigation.propTypes = {
  activeItem: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PostNavigation;
